section[id='menu'] {
    margin-top: 0;
    padding-top: 0 !important;
}

.MenuHeader {
    position: relative;
    width: 100%;
    text-align: center;
    color: #eee;
}

section {
    padding-bottom: 99px;
    overflow: hidden;
}

.section-bg {
    background-color: #1a1814;
    min-height: 100vh;
}

/*--------------------------------------------------------------
  # Menu Section
  --------------------------------------------------------------*/
.menu #menu-flters {
    position: fixed;
    padding: 0;
    margin: 0 auto 0 auto;
    list-style: none;
    text-align: center;
    background-color: #222;
    // margin: 2px 0;
    padding: 3.9px 0;
    width: 100%;
    z-index: 999;
}

.menu #menu-flters li {
    position: relative;
    cursor: pointer;
    display: inline-block;
    padding: 8px 12px 10px 12px;
    line-height: 1;
    color: #fff;
    margin-bottom: 10px;
    transition: all ease-in-out 369ms;
    top: 11.9px;
}

.menu-container {
    position: relative;
    top: 69px;
    padding-bottom: 69px;
}

.menu #menu-flters li:hover,
.menu #menu-flters li.filter-active {
    color: #cda45e;
    background-color: #1b1b1b;
}

.menu #menu-flters li.filter-active {
    border-bottom: 1px solid #cda45e;
}

.menu #menu-flters li:last-child {
    margin-right: 0;
}

.menu .menu-item {
    margin-top: 50px;
}

.menu .menu-img {
    width: 70px;
    border-radius: 50%;
    float: left;
    border: 5px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.menu .menu-content {
    margin-left: 85px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.menu .menu-content::after {
    content: "......................................................................""....................................................................""....................................................................";
    position: absolute;
    left: 20px;
    right: 0;
    top: -4px;
    z-index: 1;
    color: #bab3a6;
}

.menu .menu-content a {
    padding-right: 10px;
    padding-left: 10px;
    background: #1a1814;
    position: relative;
    z-index: 3;
    font-weight: 700;
    color: white;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
}

.menu .menu-content a:hover {
    color: #cda45e;
}

.menu .menu-content span {
    background: #1a1814;
    position: relative;
    z-index: 3;
    padding: 0 10px;
    font-weight: 600;
    color: #cda45e;
}

.menu .menu-ingredients {
    margin-left: 85px;
    font-style: italic;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.5);
}

/*--------------------------------------------------------------
  # Specials
  --------------------------------------------------------------*/
.specials {
    overflow: hidden;
}

.specials .nav-tabs {
    border: 0;
}

.specials .nav-link {
    border: 0;
    padding: 12px 15px;
    transition: 0.3s;
    color: #fff;
    border-radius: 0;
    border-right: 2px solid #cda45e;
    font-weight: 600;
    font-size: 15px;
}

.specials .nav-link:hover {
    color: #cda45e;
}

.specials .nav-link.active {
    color: #1a1814;
    background: #cda45e;
    border-color: #cda45e;
}

.specials .nav-link:hover {
    border-color: #cda45e;
}

.specials .tab-pane.active {
    animation: fadeIn 0.5s ease-out;
}

.specials .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
}

.specials .details p {
    color: #aaaaaa;
}

.specials .details p:last-child {
    margin-bottom: 0;
}

@media (max-width: 992px) {
    .specials .nav-link {
        border: 0;
        padding: 15px;
    }
}

/*--------------------------------------------------------------
  # Events
  --------------------------------------------------------------*/
.events {
    background: url(./img/events-bg.jpg) center center no-repeat;
    background-size: cover;
    position: relative;
}

.events::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.events .section-title h2 {
    color: #fff;
}

.events .container {
    position: relative;
}

@media (min-width: 1024px) {
    .events {
        background-attachment: fixed;
    }
}

.events .events-carousel {
    background: rgba(255, 255, 255, 0.08);
    padding: 30px;
}

.events .event-item {
    color: #fff;
}

.events .event-item h3 {
    font-weight: 600;
    font-size: 26px;
    color: #cda45e;
}

.events .event-item .price {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 15px;
}

.events .event-item .price span {
    border-bottom: 2px solid #cda45e;
}

.events .event-item ul {
    list-style: none;
    padding: 0;
}

.events .event-item ul li {
    padding-bottom: 10px;
}

.events .event-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #cda45e;
}

.events .event-item p:last-child {
    margin-bottom: 0;
}

.events .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

.events .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.events .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #cda45e;
}

/*--------------------------------------------------------------
  # Book A Table
  --------------------------------------------------------------*/
.book-a-table .php-email-form {
    width: 100%;
}

.book-a-table .php-email-form .form-group {
    padding-bottom: 8px;
}

.book-a-table .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.book-a-table .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
}

.book-a-table .php-email-form .error-message br+br {
    margin-top: 25px;
}

.book-a-table .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.book-a-table .php-email-form .loading {
    display: none;
    text-align: center;
    padding: 15px;
}

.book-a-table .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #cda45e;
    border-top-color: #1a1814;
    animation: animate-loading 1s linear infinite;
}

.book-a-table .php-email-form input,
.book-a-table .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: #0c0b09;
    border-color: #625b4b;
    color: white;
}

.book-a-table .php-email-form input::-moz-placeholder,
.book-a-table .php-email-form textarea::-moz-placeholder {
    color: #a49b89;
}

.book-a-table .php-email-form input::placeholder,
.book-a-table .php-email-form textarea::placeholder {
    color: #a49b89;
}

.book-a-table .php-email-form input:focus,
.book-a-table .php-email-form textarea:focus {
    border-color: #cda45e;
}

.book-a-table .php-email-form input {
    height: 44px;
}

.book-a-table .php-email-form textarea {
    padding: 10px 12px;
}

.book-a-table .php-email-form button[type=submit] {
    background: #cda45e;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.book-a-table .php-email-form button[type=submit]:hover {
    background: #d3af71;
}

/*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    box-sizing: content-box;
    min-height: 320px;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50%;
    margin: -40px 0 0 40px;
    position: relative;
    z-index: 2;
    border: 6px solid rgba(255, 255, 255, 0.2);
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 45px;
    color: white;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0 0 0 45px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: #d3af71;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 15px 0 15px;
    padding: 20px 20px 60px 20px;
    background: #26231d;
    position: relative;
    border-radius: 6px;
    position: relative;
    z-index: 1;
}

.testimonials .swiper-pagination {
    margin-top: 30px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: rgba(255, 255, 255, 0.4);
    opacity: 1;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #cda45e;
}

/*--------------------------------------------------------------
  # Gallery
  --------------------------------------------------------------*/
.gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #454035;
    border-bottom: 3px solid #454035;
}

.gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
    transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Chefs
  --------------------------------------------------------------*/
.chefs .member {
    text-align: center;
    margin-bottom: 20px;
    background: #343a40;
    position: relative;
    overflow: hidden;
}

.chefs .member .member-info {
    opacity: 0;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.2s;
}

.chefs .member .member-info-content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    transition: bottom 0.4s;
}

.chefs .member .member-info-content h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
    color: #fff;
}

.chefs .member .member-info-content span {
    font-style: italic;
    display: block;
    font-size: 13px;
    color: #fff;
}

.chefs .member .social {
    position: absolute;
    left: 0;
    bottom: -38px;
    right: 0;
    height: 48px;
    transition: bottom ease-in-out 0.4s;
    text-align: center;
}

.chefs .member .social a {
    transition: color 0.3s;
    color: #fff;
    margin: 0 10px;
    display: inline-block;
}

.chefs .member .social a:hover {
    color: #cda45e;
}

.chefs .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

.chefs .member:hover .member-info {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0.8) 20%, rgba(0, 212, 255, 0) 100%);
    opacity: 1;
    transition: 0.4s;
}

.chefs .member:hover .member-info-content {
    bottom: 60px;
    transition: bottom 0.4s;
}

.chefs .member:hover .social {
    bottom: 0;
    transition: bottom ease-in-out 0.4s;
}

/*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
.contact .info {
    width: 100%;
}

.contact .info i {
    font-size: 20px;
    float: left;
    width: 44px;
    height: 44px;
    background: #cda45e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
}

.contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
}

.contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #bab3a6;
}

.contact .info .open-hours,
.contact .info .email,
.contact .info .phone {
    margin-top: 40px;
}

.contact .php-email-form {
    width: 100%;
}

.contact .php-email-form .form-group {
    padding-bottom: 8px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #cda45e;
    border-top-color: #1a1814;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    background: #0c0b09;
    border-color: #625b4b;
    color: white;
}

.contact .php-email-form input::-moz-placeholder,
.contact .php-email-form textarea::-moz-placeholder {
    color: #a49b89;
}

.contact .php-email-form input::placeholder,
.contact .php-email-form textarea::placeholder {
    color: #a49b89;
}

.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
    border-color: #cda45e;
}

.contact .php-email-form input {
    height: 44px;
}

.contact .php-email-form textarea {
    padding: 10px 12px;
}

.contact .php-email-form button[type=submit] {
    background: #cda45e;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: #d3af71;
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
#footer {
    background: black;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-top {
    background: #0c0b09;
    border-top: 1px solid #37332a;
    border-bottom: 1px solid #28251f;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 300;
    text-transform: uppercase;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #28251f;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #cda45e;
    color: #fff;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #cda45e;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #cda45e;
}

#footer .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #28251f;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    border: 1px solid #454035;
}

#footer .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
    background: #28251f;
    color: white;
}

#footer .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px 2px 20px;
    background: #cda45e;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

#footer .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #d3af71;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

.menu-group-header,
.menu-group-header-ExteraInfo {
    position: relative;
    margin: 0 auto;
    width: 90%;
    height: auto;
    background-color: #201f1f;
    text-align: center;
    padding: 23.9px 13.9px;
    margin-top: 29.9px;
    color: #eeeeee;
    box-shadow: 0 0 3px 1px #222;
    border-radius: 3.9px;
}

.menu-group-header-ExteraInfo {
    padding: 9px 13.9px;
    margin-top: 13.9px;
}

.menu-group-header-ExteraInfo label {
    line-height: 30px;
    color: #cda45e;
}

.progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 6.9px;
    background: #cda45e;
    transform-origin: 0%;
    z-index: 9999;
}

.G9ImagePreviewer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #222;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    animation-name: showPictureAnimation;
    animation-duration: 69ms;
    animation-iteration-count: 1; 
    animation-fill-mode: forwards;
}

.G9ImagePreviewer img { 
    max-width: 83.69%;
    max-height: 83.69%;
    box-shadow: 0 0 3.9px 2px #eeeeee;
    animation-name: showPictureAnimation;
    animation-duration: 369ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#phone-number{
    position: fixed;
    bottom: 0;
    left: 0;
    right: auto;
    float: left;
    background-color: red;
    width: 120px;
    padding: 6.9px;
    text-align: center;
    direction: ltr;
    border-radius: 0 6px 0 0;
    font-size: 16px;
    color: #fff;
}
#phone-number *{
    margin: 3px;
}

#phone-number a{
    color: #fff;
    text-decoration: none;
}

@keyframes showPictureAnimation {
    0% {
        opacity: 0;
        scale: 0;
    }

    20% {
        opacity: 0.01;
        scale: 0.01;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}

#MainStarterDiv[dir="rtl"] {
    .menu-img {
        float: right;
    }

    .menu-ingredients,
    .menu-content {
        margin-left: 0;
        margin-right: 85px;
    }

    .menu-ingredients {
        position: relative;
        top: 6.9px;
        box-sizing: content-box;
        padding-right: 9.9px;
        padding-left: 9.9px;
    }

    .menu .menu-content a {
        font-family: 'Yekan 3';
    }

    .menu .menu-content span {
        font-family: 'Farnaz';
        font-size: 21px;
        top: -3px;
        font-weight: unset;
        letter-spacing: 1.369px;
    }

    .menu .menu-ingredients {
        font-family: 'Yekan 2';
    }

    .menu-group-header label {
        font-family: 'Yekan 5';
        line-height: 30px;
    }

    .menu-group-header-ExteraInfo label {
        font-family: 'Yekan 3';
        line-height: 30px;
    }

    .menu #menu-flters li {
        font-family: 'Yekan 3';
        font-size: 12px;
    }
}

// X-X-Small Mobile Mode
@media (max-width: 576px) {
    .menu-group-header {
        margin-top: 49.9px;
    }

    .menu-group-header label {
        font-size: 1.6rem;
    }

    .menu-container {
        top: 109px;
    }
}

// X-Small
@media (min-width: 576px) {
    .menu-group-header {
        margin-top: 49.9px;
    }

    .menu-group-header label {
        font-size: 2.5rem;
    }

    .menu-container {
        top: 69px;
    }
}

// Small
@media (min-width: 576px) and (min-width: 768px) {
    .menu-group-header {
        margin-top: 39.9px;
    }

    .menu-group-header label {
        font-size: 3rem;
    }
}

// Medium
@media (min-width: 768px) and (min-width: 992px) {
    .menu-group-header {
        margin-top: 29.9px;
    }

    .menu-group-header label {
        font-size: 3rem;
    }
}

// Large
@media (min-width: 992px) and (min-width: 1200px) {
    .menu-group-header {
        margin-top: 29.9px;
    }

    .menu-group-header label {
        font-size: 3rem;
    }
}

// Extra Large
@media (min-width: 1200px) and (min-width: 1400px) {
    .menu-group-header {
        margin-top: 29.9px;
    }

    .menu-group-header label {
        font-size: 3rem;
    }
}

// Extra extra large
@media (min-width: 1400px) and (min-width: 14000px) {
    .menu-group-header {
        margin-top: 29.9px;
    }

    .menu-group-header label {
        font-family: 'Yekan 5';
        font-size: 3rem;
        line-height: 30px;
    }

    .menu-group-header label {
        font-size: 3rem;
    }
}







.HideAnimation {
    display: none;
}

.ShowAnimation {
    display: inline;
    position: relative;
    animation-name: showAnimation;
    animation-duration: 369ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes showAnimation {
    0% {
        opacity: 0;
        scale: 0;
    }

    20% {
        opacity: 0.01;
        scale: 0.01;
    }

    100% {
        opacity: 1;
        scale: 1;
    }
}