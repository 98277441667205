$myColor: red;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }



// X-X-Small Mobile Mode
@media (max-width: 576px) {
}

// X-Small
@media (min-width: 576px) {
}

// Small
@media (min-width: 576px) and (min-width: 768px) {
}

// Medium
@media (min-width: 768px) and (min-width: 992px) {
}

// Large
@media (min-width: 992px) and (min-width: 1200px) {
}

// Extra Large
@media (min-width: 1200px) and (min-width: 1400px) {
}

// Extra extra large
@media (min-width: 1400px) and (min-width: 14000px) {
}